.home .homeBanner {
    width: 100%;
    /* height: 600px; */
    margin-bottom: 60px;
}

.home .homeBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 5;
}

.homeBannerCaption {
    background: #fef3f4;
    padding: 60px;
}

.homeBannerCaption h1 {
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 15px;
    color: var(--primColor);
}

.homeBannerCaption h1 span {
    color: #f36e21;
}

.homeBannerCaption p {
    font-size: 14px;
    color: var(--primColor);
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 0;
}

.homeAbout {
    position: relative;
    margin-top: 60px;
    margin-bottom: 30px;
}

.homeAbout::before {
    position: absolute;
    content: '';
    bottom: -210px;
    left: 0;
    width: 100%;
    height: 700px;
    -webkit-clip-path: polygon(0 0, 100% 29%, 100% 100%, 0 66%);
    clip-path: polygon(0 0, 100% 29%, 100% 100%, 0 66%);
    background: #fef3f4;
    z-index: 0;
}

.homeAbout > * {
    z-index: 1;
    position: relative;
}

.homeAbout .aboutBanner {
    height: 450px;
    width: 100%;
}

.homeAbout .aboutBanner {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin-bottom: 60px;
}

.homeAbout h3 {
    font-size: 30px;
    margin-bottom: 25px;
    text-align: left;
}

.homeAbout p {
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 30px;
    text-align: left;
    font-weight: 300;
}

.homeAuctions {
    position: relative;
}

.haTitle {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 80px;
    text-align: left;
}

.homeAuctions {
    margin-bottom: 60px;
}

.featuredCard {
    /* box-shadow: 0px 3px 10px rgb(88 88 88 / 16%); */
    grid-gap: 15px;
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 15px;
    margin-left: 8px;
    margin-right: 8px;
    min-height: 250px;
    border: 1px solid #f2f2f2;
}

.featuredCard .fcLt {
    /* width: 260px;
    height: 240px; */
    aspect-ratio: 1 / 1;
}

.featuredCard .fcLt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuredCard .fcRt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.featuredCard .fcRt h2 {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: auto;
}

.featuredCard .fcRt .fcBid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.featuredCard .fcRt .fcBid h4 {
    font-size: 14px;
    margin: 0;
}

.featuredCard .fcRt .fcBid h4 span {
    margin-left: 10px;
}

.featuredCard .fcTimer {
    padding: 10px;
    background: #e7e8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: var(--primColor);
    line-height: initial;
}

.featuredCard .biddingCnt .primButton,
.featuredCard .biddingCnt .secButton {
    width: 100%;
}

.featuredCard .biddingCnt.sealedBidding {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.featuredCnt .slick-dots li {
    background: #a0a7b0;
    height: 3px;
    width: 50px;
    margin: 0 2px;
}

.featuredCnt .slick-dots li.slick-active {
    background: var(--primColor);
}

.featuredCnt .slick-dots li button {
    padding: 0;
    height: 100%;
    width: 100%;
}

.featuredCnt .slick-dots li button:before {
    display: none;
}

.featuredCnt .slick-list {
    margin-bottom: 80px;
}

.featuredCnt .slick-dots {
    bottom: -40px;
}

.featuredTitle {
    font-size: 30px;
    text-align: center;
    margin-bottom: 35px;
}

.heroBanner {
    margin-bottom: 10px;
}

/* img.bannerImg {
    height: 650px;
    width: 100%;
    object-fit: cover;
    object-position: center;
} */

.heroCarousel {
    position: relative;
}

.heroCarousel .slick-dots {
    bottom: 20px;
}

.heroCarousel .slick-dots li button:before {
    font-size: 24px;
    opacity: 0.5;
}

.heroCarousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: var(--primColor);
}

.heroCarousel .slick-dots li,
.heroCarousel .slick-dots li button {
    width: 24px;
}

.heroCarousel .slick-next,
.heroCarousel .slick-prev {
    top: 36%;
}

.heroCarousel .slick-next {
    right: 40px;
    z-index: 1;
}

.heroCarousel .slick-prev {
    left: 40px;
    z-index: 1;
}

.heroCarousel .slick-next::before {
    font-family: 'Material Icons';
    content: '\e5e1';
    font-size: 35px;
}

.heroCarousel .slick-prev::before {
    font-family: 'Material Icons';
    content: '\e5e0';
    font-size: 35px;
}

.heroCarousel .bannerContent {
    position: absolute;
    z-index: 10;
    bottom: 20%;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
}
.heroCarousel .bannerContent h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    filter: drop-shadow(0px 1px 2px #333);
}

.heroCarousel .bannerContent h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    filter: drop-shadow(0px 2px 3px #333);
}

.heroCarousel .bannerContent button {
    background: transparent;
    border-radius: 0px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    height: 45px;
    margin-top: 15px;
}

.heroCarousel .bannerContent button .MuiButton-label {
    text-transform: initial;
}

@media (max-width: 1024px) {
    /* .home .homeBanner {
        height: 450px;
    } */

    /* .home .homeBanner .slick-slide img {
        height: 450px;
    } */

    header .headRt ul li:not(:last-child) {
        margin-right: 0;
    }
    .featuredCard {
        grid-template-columns: 1fr;
    }
    .featuredCard .fcRt h2 {
        margin-bottom: 20px;
        font-size: 16px;
        height: 6ex;
    }
}

@media (max-width: 991px) {
    .homeAbout p {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 32px;
    }
}

@media (max-width: 767px) {
    .heroCarousel .bannerContent h3 {
        font-size: 20px;
    }

    .heroCarousel .bannerContent h1 {
        color: #fff;
        font-size: 35px;
    }
}

@media (max-width: 600px) {
    .homeAbout .aboutBanner {
        height: 200px;
    }
    .homeAbout h3,
    .haTitle {
        font-size: 24px;
        margin-bottom: 15px;
    }
    /* .home .homeBanner {
        height: 500px;
    } */
    .featuredCard .fcLt {
        margin: 0 auto;
        max-width: 250px;
    }

    .featuredCard .sealedBiddingCnt {
        margin-bottom: 10px;
    }
}

@media (max-width: 500px) {
    .homeAbout::before {
        display: none;
    }

    .homeAbout {
        background: #fef3f4;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .homeAbout .aboutBanner {
        margin-bottom: 30px;
    }
    .haTitle {
        margin-top: 40px;
    }

    .homeAuctions .pagination-wrapper {
        align-items: center;
    }

    .heroCarousel .slick-next::before,
    .heroCarousel .slick-prev::before {
        font-size: 24px;
    }

    .heroCarousel .slick-next {
        right: 20px;
    }
    .heroCarousel .slick-prev {
        left: 20px;
    }
}

@media (max-width: 480px) {
    .featuredCard {
        margin: 0;
    }
    .home .homeBanner img {
        aspect-ratio: 8 / 5;
    }
    .home .homeBanner {
        height: 100%;
    }

    .heroCarousel .bannerContent {
        bottom: 30%;
    }
}
