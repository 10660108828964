.search {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.search .searchHead {
    margin-bottom: 30px;
}

.search .searchHead h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.search .searchHead h4 span {
    font-style: italic;
}

.search .searchHead button .material-icons {
    padding-inline-end: 5px;
}

.search .searchHead button .MuiButton-label {
    color: var(--secColor);
    text-transform: initial;
    font-weight: 700;
}

.search .searchResults .productCardGrid {
    /* margin-inline-end: 5px; */
}

.search .searchLt .customSelect {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.search .searchLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.search .searchLt .gridListToggle i {
    padding-inline-end: 10px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 60vmax;
    max-width: 800px;
    padding: 20px;
}

.search .filterCheck .customCheckbox {
    width: 100%;
}

.search .searchBox {
    max-width: 400px;
    width: 100%;
}

.search .searchBox input {
    height: 40px;
    border-radius: 0;
    width: 100%;
    padding-right: 40px;
}

.search .searchBox input:focus {
    box-shadow: none;
    border-color: var(--primColor);
}

.search .searchBox .MuiButtonBase-root.MuiButton-root {
    position: absolute;
    right: 0;
    border-radius: 0;
    padding: 2px;
    cursor: pointer;
    top: 0;
    height: 100%;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--primColor);
    color: #fff;
    min-width: unset;
    margin-right: 0;
    border: 1px solid var(--primColor);
}

.savedSearchView {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f3f3f3;
    padding: 10px 20px;
    margin-bottom: 25px;
}

.savedSearchView h4 {
    margin: 0;
    font-size: 18px;
}

.savedSearchView .MuiButtonBase-root .material-icons-outlined {
    margin-right: 5px;
}

@media (max-width: 1024px) {
    .home .hmSearch .searchInput,
    .search .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .searchLt {
        display: none;
    }

    .sbTitle {
        font-size: 14px;
        color: var(--secColor);
        font-weight: 700;
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        font-size: 14px;
        color: var(--secColor);
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 0px;
    }

    .searchAuctions {
        margin-bottom: 20px;
    }

    /* .searchMiscFilters .customSelect {
        display: none;
    } */

    .searchAuctions {
        margin-bottom: 20px;
    }

    .searchAuctions label,
    .searchAuctions button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        color: #000000de;
        height: 50px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        border: 1px solid #d8d8d8;
        border-radius: 25px;
        margin-inline-end: 10px;
        text-decoration: none;
        transition: 200ms all ease-in-out;
        font-size: 16px;
        font-weight: 600;
        text-transform: initial;
        padding: 5px 15px;
        margin-bottom: 10px;
        height: 40px;
    }

    .searchAuctions button.active {
        color: var(--primColor);
        background: #fff2e7;
        border: 1px solid rgb(25 157 229 / 20%) !important;
    }

    .searchAuctions button .MuiButton-label {
        font-size: 13px;
    }
}

@media (max-width: 600px) {
    .searchAuctions label {
        height: 40px;
        font-size: 14px;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
    .productView .pvBidBox .customInput,
    .productView .pvBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }
    .productView .pvBidBox .customInput input {
        height: 100%;
    }
    .productView .pvActBtn {
        flex-wrap: wrap;
    }
    .productView .pvActBtn .pabChild:first-child {
        width: 100%;
    }
    .search .searchHead {
        margin-bottom: 5px;
    }
}
