.search {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.search .searchResults .productCardGrid {
    /* margin-inline-end: 5px; */
}

.search .searchLt .customSelect {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.search .searchLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.search .searchLt .gridListToggle i {
    padding-inline-end: 10px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 60vmax;
    max-width: 800px;
    padding: 20px;
}
.searchMiscFilters .customInput {
    margin-bottom: 0px;
    min-width: 20vh;
}

@media (max-width: 1024px) {
    .home .hmSearch .searchInput,
    .search .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .searchLt {
        display: none;
    }

    .sbTitle {
        font-size: 14px;
        color: var(--secColor);
        font-weight: 700;
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .searchAuctions {
        margin-bottom: 20px;
    }

    .searchAuctions label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        color: var(--primColor);
        height: 50px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        border: 1px solid #d8d8d8;
        border-radius: 25px;
        margin-inline-end: 10px;
        text-decoration: none;
        transition: 200ms all ease-in-out;
        font-size: 16px;
        font-weight: 600;
    }

    .searchAuctions input[type='radio']:checked + label {
        background: rgb(249 238 236);
        color: var(--primColor);
        border: 1px solid rgb(174 34 7 / 51%);
    }
}

@media (max-width: 600px) {
    .searchAuctions label {
        height: 40px;
        font-size: 14px;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
    .productView .pvBidBox .customInput,
    .productView .pvBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }
    .productView .pvBidBox .customInput input {
        height: 100%;
    }
    .productView .pvActBtn {
        flex-wrap: wrap;
    }
    .productView .pvActBtn .pabChild:first-child {
        width: 100%;
    }
}
