body {
    margin: 0;
    font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
    scroll-behavior: smooth;
}

.initalLoadingCnt {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 30px !important;
    padding-inline-end: 30px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

#notistack-snackbar {
    font-family: var(--fontFamily);
}

.MuiTypography-root,
.MuiButton-root,
.MuiButton-label,
.MuiTypography-root.MuiTypography-body1,
.MuiInputBase-root.MuiOutlinedInput-root,
.MuiFormLabel-root.MuiInputLabel-root,
.MuiFormControl-root .MuiFormHelperText-root {
    font-family: var(--fontFamily) !important;
    letter-spacing: initial;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
    /* width: 100%; */
    border-radius: 0;
}

.customSelect .MuiSelect-outlined,
.customSelect fieldset {
    border-radius: 0;
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--primColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--primColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor) !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.customInput .MuiFormLabel-root,
.customSelect .MuiFormLabel-root,
.MuiInputBase-root,
.MuiTypography-body1 {
    font-weight: 500 !important;
}

.MuiFormHelperText-root.Mui-error {
    font-weight: 500 !important;
    margin-left: 0;
    margin-right: 0;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 305px);
    overflow: hidden;
}

.search .searchRt {
    max-width: calc(100% - 0px);
}

.search .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: initial;
    width: 40px;
    height: 40px;
    border: 1px solid #ececec;
    color: var(--primColor);
    margin-right: 10px;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    background: #e7e8eb;
}

.search .searchMiscFilters .customSelect .MuiFormControl-root.MuiTextField-root {
    margin-top: 5px;
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 10px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .MuiButton-label {
    text-transform: initial;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-right: 5px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.customBreadcrumbs p .material-icons {
    padding: 0 5px;
}

.dpTable table {
    color: #13233a;
}

.dpTable table thead th {
    background: #13233a;
    color: #fff;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
}

.searchSkeletonBody {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.userInitials.small {
    width: 30px;
    height: 30px;
    min-width: initial;
    font-size: 14px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 90px;
    height: 90px;
    font-size: 28px;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.regDivider {
    margin-bottom: 35px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 60vmax;
    max-width: 800px;
    padding: 20px;
}

.pvTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pvTimerView h6 {
    font-size: 20px;
    font-weight: 800;
    color: #0a0a0a;
    margin-bottom: 0;
}

.pvTimerView h6:not(:last-child) {
    margin-right: 25px;
}

.pvTimerView h6 span {
    display: block;
    font-size: 12px;
    font-weight: 700;
    color: #b5b5b5;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.savedBankCnt {
    display: grid;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.savedBankCnt .savedBank {
    padding: 15px;
    text-align: left;
    background: #ebebeb;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin: 0;

    background-image: linear-gradient(to right, #b0fbff 0%, #4facfe 100%);
}

.savedBankCnt .savedBank .material-icons {
    font-size: 45px;
    color: var(--primColor);
}

.savedBankCnt .savedBank h4 {
    font-size: 16px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #333;
}

.savedBankCnt .savedBank h5 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: #333;
    letter-spacing: 4px;
}

.savedBankCnt .savedBank.addNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px dashed #ddd;
    cursor: pointer;
}

.savedBankCnt .savedBank.addNew .material-icons {
    margin-bottom: 20px;
    color: #abaeb3;
}

.savedBankCnt .savedBank.addNew h6 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #275168;
}

.savedBankCnt .savedBank h4 span,
.savedBankCnt .savedBank h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.savedBankCnt .savedBank .edtBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
}

.savedBankCnt .savedBank .dltBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: #dc3545;
}

.loadingCnt .listViewSkeleton {
    background: transparent;
}

.favoriteCheck {
    background: transparent !important;
    height: auto !important;
    margin: 0 0 0 8px !important;
}

.favoriteCheck label {
    color: #dc1c34 !important;
}

.bidCnfDialog.customDialog .MuiPaper-root.MuiDialog-paper {
    border-radius: 16px;
    min-width: 580px;
}

.bidCnfDialog.customDialog h5 {
    font-size: 18px;
    color: var(--primColor);
    margin-bottom: 25px;
}

.bidCnfDialog.customDialog h6 {
    font-size: 18px;
    color: #4d627a;
    margin-bottom: 20px;
}

.bidCnfDialog.customDialog h6 span {
    margin-left: 20px;
    color: var(--primColor);
}

.bidCnfDialog.customDialog .actionWrapper {
    justify-content: flex-start;
    margin-top: 40px;
}

.bidCnfDialog.customDialog .actionWrapper > button:first-child {
    margin-right: 15px;
    color: var(--primColor);
    border-radius: 0;
    min-width: 160px;
}

.bidCnfDialog.customDialog .actionWrapper > .primButton button {
    min-width: 220px;
}

.bidCnfDialog.customDialog .MuiDialogTitle-root h2 {
    font-weight: 500;
    font-size: 24px;
    color: var(--primColor);
    margin: 5px 0 10px;
}

.gridListToggle button {
    min-width: initial;
    width: 40px;
    height: 40px;
    border: 1px solid #ececec;
    color: var(--primColor);
    margin-right: 10px;
    border-radius: 0;
    color: #607491;
}

.gridListToggle button.active {
    background: #e7e8eb;
    color: var(--primColor);
}

.noGridLoader .loadingCnt {
    display: block;
}

.noGridLoader .skeletonWrapper {
    box-shadow: none;
}

.activeBid,
.activeBid > * {
    font-weight: 700;
}

.pagination-wrapper h6 {
    margin: 0;
}

.MuiButtonBase-root.MuiButton-root,
.MuiButtonBase-root.MuiButton-root:hover,
.MuiButtonBase-root.MuiButton-root:focus {
    outline: 0;
}

.noScroll .App {
    height: 100vh;
    overflow: hidden;
}

.albumTitleCnt {
    font-weight: 300;
    font-size: 32px;
    padding-bottom: 22px;
    border-bottom: 1px solid #bcbcbc70;
    margin-bottom: 32px;
    letter-spacing: 1.5px;
}

.actnDtlWrpr .actnTtrl {
    font-size: 24px;
    color: var(--primColor);
    letter-spacing: 1.25px;
}

.albumAssetsCnt .actnDtlWrpr .acContent .actnTtrl h2 {
    font-size: 32px;
    letter-spacing: 1.25px;
}

.titleClass .dialogTitle {
    display: inline-block;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.homeInitialLoader {
    margin: 40px auto 100px auto;
    max-width: 1600px;
}

.homeInitialLoader .adsBannerSkeleton .absItem .skeleton.title {
    height: 490px;
    width: 100%;
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff2e7;
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* @media (max-width: 991px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }
} */

.MuiDivider-root {
    /* background-color: rgb(149 208 242 / 55%) !important; */
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }
}

@media (max-width: 600px) {
    .bidCnfDialog.customDialog .MuiPaper-root.MuiDialog-paper {
        min-width: 90%;
    }

    .bidCnfDialog.customDialog .MuiDialogTitle-root h2 {
        font-size: 20px;
        margin: 5px 0 5px;
    }

    .bidCnfDialog.customDialog h5 {
        font-size: 16px;
    }

    .bidCnfDialog.customDialog h6 {
        font-size: 16px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }

    .liveAuction.albumAuction .MuiBreadcrumbs-ol,
    .liveAuction.packview .MuiBreadcrumbs-ol {
        display: flex;
        flex-wrap: nowrap;
    }

    .truncateText {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        /* width: calc(100% - 8px); */
        text-align: left;
    }
}

@media (max-width: 500px) {
    .bidCnfDialog.customDialog .actionWrapper > button:first-child,
    .bidCnfDialog.customDialog .actionWrapper > .primButton button {
        min-width: auto;
    }

    .customDialog .MuiDialogContent-root h5 {
        font-size: 16px;
    }
    .bidCnfDialog.customDialog .MuiDialogTitle-root h2 {
        font-size: 18px;
    }
    .bidCnfDialog.customDialog h5 {
        margin-bottom: 20px;
        font-size: 16px;
    }

    .bidCnfDialog.customDialog h6 {
        margin-bottom: 15px;
    }

    .pagination-wrapper {
        flex-wrap: wrap;
    }

    .pagination-wrapper h6,
    .pagination-wrapper nav {
        width: 100%;
    }
    .pagination-wrapper h6 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .pagination-wrapper nav ul {
        justify-content: center;
    }

    .termsModal h1 {
        font-size: 22px;
    }

    .termsModal h2 {
        font-size: 20px;
    }

    .termsModal h3 {
        font-size: 18px;
    }
    .termsModal h4 {
        font-size: 16px;
    }

    .termsModal h5 {
        font-size: 15px;
    }
    .termsModal h6 {
        font-size: 14px;
    }

    .termsModal p,
    .termsModal li {
        font-size: 15px;
    }
    .termsModal.customDialog .MuiDialogContent-root {
        overflow-x: hidden;
    }

    .termsModal.customDialog .actionWrapper {
        margin-top: 5px;
    }

    .termsModal.customDialog .customCheckbox .MuiListItem-root {
        flex-wrap: wrap;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }
}

@media (max-width: 360px) {
    .bidCnfDialog.customDialog h6 {
        display: flex;
        flex-direction: column;
    }
    .bidCnfDialog.customDialog h6 span {
        margin-left: 0;
        width: 100%;
        margin-top: 8px;
    }

    .bidCnfDialog.customDialog .actionWrapper {
        flex-direction: column-reverse;
    }
    .bidCnfDialog.customDialog .actionWrapper > button:first-child {
        margin: 10px 0 0;
        width: 100%;
    }
    .bidCnfDialog.customDialog .actionWrapper > .primButton {
        width: 100%;
    }

    .auctionSearch.searchMiscFilters {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }

    .auctionSearch.searchMiscFilters .gridListToggle {
        display: none;
    }

    .auctionSearch.searchMiscFilters .customSelect {
        min-width: 100%;
        margin-inline-start: 0;
    }

    .auctionSearch.searchMiscFilters .customSelect .MuiSelect-root {
        font-size: 14px;
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}
