.App {
    text-align: center;
    background-color: #fff;
    /* max-width: 1600px; */
    min-height: 100vh;
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
    display: flex;
    flex-direction: column;
}
