.productView .pvLt,
.productView .pvRt {
    width: 100%;
}

.productView .image-gallery-thumbnails-container {
    text-align: start;
}

.productView .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productView .image-gallery-icon:hover {
    color: var(--primColor);
}

.productView .image-gallery-thumbnail {
    border: 1px solid transparent;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
    outline: none;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
}

.productView .pvTimerBox {
    background: #e7e8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #383838;
    padding: 15px;
    border-radius: 0;
    margin: 30px 0 30px 0;
}

.productView .pvTimerBox > * {
    margin-bottom: 0;
}

.productView .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.productView .pvTimerBox h4 {
    font-size: 16px;
}

.productView .pvActBtn .pabChild {
    text-align: center;
}

.productView .pvActBtn {
    background: #f8f8f8;
    padding: 15px 10px;
}

.productView .pvBidBox {
    width: 100%;
    margin: 20px auto 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productView .pvBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productView .pvBidBox .biddingCnt > div:only-child {
    width: 100%;
}

.productView .pvBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productView .pvBidBox .customInput,
.productView .customInput .MuiTextField-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root input {
    height: 50px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-top: 5px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 0px;
}

.productView .pvBidBox .tertButton {
    width: 100%;
    margin-inline-start: 10px;
}

.productView .pvBidBox .tertButton,
.productView .pvBidBox .tertButton button {
    height: 50px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton,
.productView .pvBidBox .primButton button,
.productView .pvBidBox .secButton button {
    height: 50px;
    font-weight: 700;
}

.productView .pvBidBox form {
    width: 60%;
    margin: 0 auto;
}

.productView .pvBidBox form:not(:nth-child(1)) {
    margin-top: 30px;
}

.productView .pvActBtn .pabChild label {
    font-size: 14px;
    color: #373737;
}

.productView .pvActBtn .pabChild h4 {
    font-size: 18px;
    color: #373737;
    font-weight: 700;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
    color: var(--primColor);
}

.productView .pvBidHistBtn {
    color: var(--primColor);
    text-transform: initial;
    margin: 10px auto;
    font-weight: 600;
    display: flex;
}

.productView .pvBidHistBtn .MuiButton-label .material-icons {
    padding-inline-end: 10px;
}

.productView .pvBidBox > div {
    margin: 0;
    width: 49%;
    margin-inline-end: 10px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton {
    width: 49%;
    margin: 0 auto;
}

.productView .pvProductInfo {
    margin-top: 30px;
}

.productView .pvProductInfo h2 {
    font-size: 28px;
    color: var(--primColor);
    margin: 12px 0 10px;
}

.productView .pvProductInfo h5 {
    font-size: 16px;
    color: #646464;
    margin: 0 0 8px 0;
}

.productView .pvProductDesc label {
    font-size: 15px;
    color: #373737;
    font-weight: 600;
    margin: 0;
    padding-inline-end: 10px;
}

.productView .pvProductDesc h6 {
    font-size: 15px;
    color: #646464;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.productView .pvProductDesc .pvDescChild {
    margin-bottom: 10px;
}

.productView .cartHead {
    font-size: 24px;
    color: #353535;
    font-weight: 700;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
}

.productView .MuiAccordionDetails-root {
    font-size: 15px;
}

.productView .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 500px;
    min-height: 300px;
}

.productView .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.productView .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: -20px;
    z-index: 10;
}

.productView .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 5px;
}

.productView .pvBidStatus h4.winning {
    background: #6aa73a;
}

.productView .pvBidStatus h4.won {
    background: #06a2b4;
}

.productView .pvBidStatus h4.outbid {
    background: #ff9472;
}

.productView .pvBidStatus h4.lost {
    background: #ff7272;
}

.productView {
    padding-top: 20px;
}

.productView .closeSlider {
    position: absolute;
    top: 5px;
    left: 10px;
    min-width: max-content;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.productSpecs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    margin-top: 30px;
}

.productSpecs .pvDescChild {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.productSpecs .pvDescChild label {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 15px;
    color: #4d627a;
}

.productSpecs .pvDescChild h6 {
    margin: 0;
    font-size: 15px;
}

.pvDesc p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: var(--primColor);
    margin-top: 20px;
}

.pvActions {
    display: flex;
    justify-content: space-between;
}

.pvActions .pvBidBox {
    margin: 0;
    width: auto;
}

.pvActions .pvBidBox form {
    width: auto;
    min-width: 400px;
    margin: 0;
}

.pvBidInfo .pabChild {
    display: flex;
    align-items: center;
}

.pvBidInfo .pabChild:first-child {
    margin-bottom: 10px;
}

.pvBidInfo .pabChild label {
    font-size: 18px;
    margin: 0 12px 0 0;
    color: #4d627a;
}

.pvBidInfo .pabChild h4 {
    margin: 0;
    color: var(--primColor);
}

.productView .favoriteCheck {
    width: auto;
    text-align: left;
    justify-content: flex-start !important;
    margin-top: 10px;
    margin-left: 0 !important;
}

.productView .favoriteCheck label {
    display: flex;
    flex-direction: row-reverse;
}

.productView .favoriteCheck label .material-icons {
    margin-left: 10px;
}

.productView .slick-slider.mainSlider .slick-slide > div,
.productView .slick-slider.mainSlider .slick-slide > div > div,
.productView .slick-slider.mainSlider .slick-slide > div > div > img {
    height: 500px !important;
    width: 100% !important;
}

.productView .slick-slider.mainSlider .slick-slide > div > div > img {
    object-fit: contain;
}

/* .productView .slick-slider.mainSlider .slick-slide > div {
    background: #f1f7fe;
} */

.productView .slick-slider.sliderThumbnail .slick-slide > div {
    width: 90px !important;
    height: 90px !important;
    cursor: pointer;
}

.productView .slick-slider.sliderThumbnail .slick-slide > div:hover {
    opacity: 0.5;
}
/* .customZoom img {
    object-fit: contain;
} */

.productView .slick-slider.sliderThumbnail .slick-slide img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border: 1px solid #f5f5f5;
}

.productView .slick-slider.sliderThumbnail {
    margin-top: 20px;
    margin-bottom: 30px;
}

.productView .offerView .valueBeforeOffer {
    height: 100%;
}

.productView .offerView .sealedBiddingCnt .primButton,
.productView .offerView .sealedBiddingCnt .secButton {
    width: 100%;
}

@media (max-width: 1200px) {
    .pvActions {
        flex-wrap: wrap;
    }
    .pvActions .pvBidBox {
        margin-top: 20px;
    }
    .pvActions .pvBidBox,
    .pvActions .pvBidBox form {
        width: 100%;
        min-width: initial;
    }
    .productSpecs {
        margin-bottom: 10px;
    }
    .pvBidInfo .pabChild label,
    .pvBidInfo .pabChild h4 {
        font-size: 16px;
    }
}

/* @media (max-width: 1024px) {
    .productView .pvBidBox form {
        width: 80%;
    }
} */

@media (max-width: 767px) {
    .productView .slick-slider.mainSlider .slick-slide > div,
    .productView .slick-slider.mainSlider .slick-slide > div > div,
    .productView .slick-slider.mainSlider .slick-slide > div > div > img {
        max-height: 500px;
        height: 100% !important;
    }
    .productView .pvProductInfo h2 {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .productView .slick-slider.sliderThumbnail .slick-slide > div {
        width: 70px !important;
        height: 70px !important;
    }
    .productView .slick-slider.sliderThumbnail {
        margin-top: 10px;
        margin-bottom: 0px;
    }
}

@media (max-width: 500px) {
    .productView .pvBidBox {
        width: 100%;
    }
    .productView .pvTimerBox {
        margin: 20px 0;
        padding: 10px;
    }
    .productView .pvTimerBox h4 {
        font-size: 15px;
    }
    .productSpecs {
        grid-template-columns: 1fr;
    }
    .productView .cartHead {
        font-size: 18px;
        color: #353535;
        font-weight: 700;
        margin: 20px 0 10px 0;
        text-transform: uppercase;
    }

    .productView .pvBidBox form {
        width: 100%;
    }

    .productView .pvBidBox form:nth-child(2) {
        margin-top: 15px;
    }

    .productView .pvBidBox .biddingCnt > div {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .productView .pvBidBox .biddingCnt {
        flex-wrap: wrap;
    }

    .productView .pvBidBox .biddingCnt .customInput {
        /* width: 100%; */
        margin-bottom: 15px;
    }

    /* .productView .pvBidBox .biddingCnt .primButton,
    .productView .pvBidBox .biddingCnt .secButton {
        width: 100%;
    } */
    .similarItem .pclImg img {
        height: 90px;
    }

    .productView .pvBidStatus h4 {
        font-size: 13px;
    }
}
