footer {
    margin-top: auto;
}

footer .footCnt {
    background: var(--primColor);
    padding-top: 60px;
    padding-bottom: 60px;
}

footer .footInnerCnt {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

footer .footCnt .footLinks {
    color: #fff;
    text-align: left;
}

footer .footCnt .footLinks ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

footer .footCnt .footLinks ul li a {
    color: #fff;
    font-weight: 300;
}

footer .footCnt .footLinks ul li:not(:last-child) {
    margin-bottom: 10px;
    margin-right: 0;
}

footer .footCnt .footLinks > h4 {
    font-size: 24px;
    font-weight: 300;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #fff;
}

footer .footCnt .footLinks .siteServices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

footer .footCnt .footLinks .footSocial {
    display: flex;
    justify-content: flex-start;
}

footer .footCnt .footLinks .footSocial img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

footer .footCnt .footLinks .footSocial li:not(:last-child) {
    margin-right: 25px;
}

@media (max-width: 767px) {
    footer .footInnerCnt {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767px) {
    footer .footCnt .footLinks ul li {
        font-size: 14px;
    }
    footer .footCnt .footLinks ul li:not(:last-child) {
        margin-bottom: 10px;
    }

    footer .footCnt .footLinks > h4 {
        font-size: 20px;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
}
